import { createWebHistory, createRouter } from "vue-router";


const routes = [
    {
        path: "/",
        name: "about",
        component: () => import("@/views/about"),
    },
    {
        path: "/wallet",
        name: "wallet",
        component: () => import("@/views/wallet"),
    },
    {
        path: "/deposit",
        name: "deposit",
        component: () => import("@/views/deposit"),
    },
    {
        path: "/withdrawal",
        name: "withdrawal",
        component: () => import("@/views/withdrawal"),
    },
    {
        path: "/protocol",
        name: "protocol",
        component: () => import("@/views/protocol"),
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/terms"),
    },
    {
        path: "/tokenomics",
        name: "tokenomics",
        component: () => import("@/views/tokenomics"),
    },
    {
        path: "/whitepapers",
        name: "whitepapers",
        component: () => import("@/views/whitepapers"),
    },
    {
        path: "/consensus",
        name: "Consensus",
        component: () => import("@/views/consensus"),
    },
    {
        path: "/node",
        name: "Node",
        component: () => import("@/views/node"),
    },
    {
        path: "/reliability",
        name: "Reliability",
        component: () => import("@/views/reliability"),
    },
    {
        path: "/roadmap",
        name: "roadmap",
        component: () => import("@/views/roadmap"),
    },
    {
        path: "/trade",
        name: "trade",
        redirect: '/trade/start',
        children: [
            {
                path: 'start',
                component: () => import("@/views/trade/start"),
            },
            {
                path: 'indivisible',
                component: () => import("@/views/trade/indivisible"),
            },
            {
                path: 'divisible',
                component: () => import("@/views/trade/divisible"),
            }
        ]
    }
];
const router = createRouter({
    scrollBehavior() {
        return { top: 0 }
    },
    history: createWebHistory(),
    routes,
});

export default router
