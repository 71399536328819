<template>
<nav class="nav" :class="{active: modelValue}">
  <div class="nav__header">
    <img src="@/assets/img/header-frame.png" class="nav__frame" alt="frame">
    <router-link to="/">
      <img src="@/assets/img/logo.png" class="nav__logo" alt="logo">
    </router-link>
  </div>
  <div class="nav__content">
    <div class="nav__subtitle">FAQ</div>
    <router-link to="/" class="nav__link" @click="open">About Us</router-link>

    <div class="nav__subtitle">Tutorial</div>
    <router-link to="/wallet" class="nav__link" @click="open">Wallet creation and connection</router-link>
    <router-link to="/deposit" class="nav__link" @click="open">Depositing tokens</router-link>
    <router-link to="/withdrawal" class="nav__link" @click="open">Withdrawal tokens</router-link>
    <router-link to="/trade" class="nav__link nav__link-sub" @click="open">
      Trading
      <div class="nav__sublinks">
        <router-link to="/trade/start" class="nav__link" @click="open">How to start trading</router-link>
        <router-link to="/trade/indivisible" class="nav__link" @click="open">Placing an indivisible lot</router-link>
        <router-link to="/trade/divisible" class="nav__link" @click="open">Placing a divisible lot</router-link>
      </div>
    </router-link>

    <div class="nav__subtitle">Other</div>
    <router-link to="/tokenomics" class="nav__link" @click="open">Tokenomics</router-link>
    <router-link to="/roadmap" class="nav__link" @click="open">Roadmap</router-link>
    <router-link to="/node" class="nav__link" @click="open">Run a Node</router-link>
    <router-link to="/gateway" class="nav__link" @click="open">Run a gateway</router-link>
    <router-link to="/whitepapers" class="nav__link" @click="open">Whitepapers</router-link>
    <router-link to="/consensus" class="nav__link" @click="open">Consensus algorithm</router-link>
    <router-link to="/reliability" class="nav__link" @click="open">Consensus reliability</router-link>
    <router-link to="/terms" class="nav__link" @click="open">Terms of service</router-link>
    <router-link to="/protocol" class="nav__link" @click="open">Protocol disclaimer</router-link>
  </div>
</nav>
</template>

<script>
export default {
  name: "NavLayout",
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    open() {
      this.$emit('update:modelValue', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  font-weight: 500;
  background: rgba(228, 229, 232, 0.49);
  color: rgba(14, 14, 14, 0.5);
  @include below($desktopSml) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgb(228, 229, 232);
    height: 100vh;
    overflow-y: auto;
    transition: transform .25s ease-out;
    transform: translateX(-100%);
    &.active {
      transform: translateX(0);
    }
  }
  &__header {
    padding-left: 50px;
    height: 62px;
    border-bottom: 1px solid #DADADA;
    display: flex;
    align-items: center;
    position: relative;
    @include below($desktopSml) {
      padding-left: 25px;
    }
  }
  &__logo {
    width: 222px;
    height: 21px;
  }
  &__frame {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
    width: 103.9px;
    height: 20px;
  }
  &__content {
    padding: 70px 0 40px 50px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 55px);
    overflow-y: auto;
    @include below($desktopSml) {
      padding: 30px 0 20px;
    }
  }
  &__link {
    color: rgba(14, 14, 14, 0.5);
    width: 100%;
    padding: 11px 0 11px 23px;
    &.router-link-active {
      background-color: white;
      color: #0E0E0E;
    }
  }
  &__link-sub.router-link-active {
    background-color: transparent;
    color: inherit;
    .nav__sublinks {
      display: flex;
    }
  }
  &__sublinks {
    margin-top: 11px;
    display: none;
    flex-direction: column;
  }
  &__subtitle {
    padding-left: 23px;
    margin-top: 50px;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 11px;
    @include below($desktopSml) {
      margin-top: 20px;
    }
  }
}
</style>