import { createApp } from 'vue'
import App from './App.vue'
import store from './router'
import "@/assets/scss/top.scss"
let app = createApp(App)

app.use(store)

app.config.devtools = true
app.mount('#app')
