<template>
  <div class="app-content">
    <navigation class="app-content__nav" v-model="activeNav" />
    <div class="app-content__wrap">
      <app-bar v-model="activeNav" />
      <main class="app-content__content">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import navigation from "@/components/layout/navigation";
import appBar from "@/components/layout/appBar";

export default {
  name: 'App',
  components: {
    navigation,
    appBar
  },
  data() {
    return {
      activeNav: false
    }
  }
}
</script>

<style lang="scss">
.app-content {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  &__wrap {
    width: 70%;
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__nav {
    width: 30%;
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__content {
    padding: 47px 43px 90px;
    overflow-y: auto;
    height: 100%;
    @include below($desktopSml) {
      padding: 20px 30px 60px;
    }
    @include below($mobileSml) {
      padding: 20px 12px 40px;
    }
  }
}
</style>
